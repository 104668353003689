import { css, cx } from 'emotion';
import * as React from 'react';
import errorIconUrl from '../../assets/errorIcon.svg';

const errorStyle = css`
  display: flex;
  align-items: center;
  margin: 0.25rem 0rem;
`;
const errorIconStyle = css`
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: -0.2rem;
  margin-left: 0.2rem;
`;

const errorTextStyle = css`
  font-family: Lato, sans-serif;
  padding-left: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.063rem;
  letter-spacing: -0.03em;
  color: var(--error);
`;

const sfTextStyle = css`
  color: var(--white);
  font-size: 0.75rem;
  font-family: Lato, sans-serif;
  font-weight: 600;
  line-height: 1.8;
`;
const sfContainerStyle = css`
  justify-content: center;
  margin: 0;
`;

export const RequiredError = props => {
  const { errorMessage, salesForce } = props;
  return (
    <div className={cx(errorStyle, salesForce && sfContainerStyle)}>
      {salesForce || <img src={errorIconUrl} className={errorIconStyle} alt="error" />}
      <p className={salesForce ? sfTextStyle : errorTextStyle}>
        {(salesForce && 'This field is required.') ||
          errorMessage?.code ||
          errorMessage?.description ||
          errorMessage ||
          'This question is required'}
      </p>
    </div>
  );
};
