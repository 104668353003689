import React from 'react';
import { css } from 'emotion';
import { CustomButton } from '../components/CustomButton';
import { Container } from '../components/Container';
import { ButtonContainer } from '../components/ButtonContainer';
import { quoteStore } from '../../store/quote-store';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { mtaStore } from '../../store/mta-store';
import finalPageCheck from '../../assets/finalPageCheck.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { renewalStore } from '../../store/renewal-store';

const contentStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const imgStyle = css`
  width: 128px;
  align-self: center;
  margin-top: 5rem;
`;

const titleStyle = css`
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  margin: 3rem 0 1.5rem 0;
`;

const centeredTextStyle = css`
  text-align: center;
  margin-bottom: 1rem;
`;

const policyNumberStyle = css`
  color: var(--primaryBrand);
  font-weight: bold;
`;

const pageContainer = css`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'progress main main';
  background-color: var(--white);
  height: 100%;
  flex: 1;
  max-height: calc(100vh - 80px);

  @media (min-width: 1201px) {
    max-height: calc(100vh - 121px);
    grid-template-areas: 'progress main info';
  }

  @media (max-width: 768px) {
    grid-template-areas: 'main';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

export function SuccessPage() {
  const usedStore = mtaStore.mta ? mtaStore : renewalStore;
  return (
    <div className="App">
      <Header />
      <div className={pageContainer}>
        <Container>
          <div className={contentStyle}>
            <img src={finalPageCheck} alt="success" className={imgStyle} />
            <h1 className={titleStyle}>{stateStore.inEditMode ? 'Update successful.' : 'Purchase successful.'}</h1>
            <p className={centeredTextStyle}>
              {stateStore.inEditMode ? 'Your policy number remains ' : 'Your policy number is '}
              <span className={policyNumberStyle}>
                {stateStore.inEditMode || stateStore.inRenewalMode ? usedStore.policyId : quoteStore.policyId}
              </span>
            </p>
            <p className={centeredTextStyle}>
              {stateStore.inEditMode
                ? 'You will receive an email shortly confirming your changes and these will now be visible in the customer portal. As always should '
                : stateStore.inRenewalMode
                ? 'As always should '
                : 'You will receive an email shortly with how you can manage your policy. Should '}
              you need to make a change to your policy, view your documents or make a claim, you can do all of this and
              more from our customer portal.
            </p>
          </div>
          <ButtonContainer>
            {(stateStore.inEditMode || stateStore.inRenewalMode) && (
              <CustomButton
                fullWidth
                handleClick={() => {
                  stepStore.step = 1;
                  window.location.href = `${process.env.REACT_APP_CUSTOMER_PORTAL}/policies`;
                }}
                label="BACK TO HOME"
              />
            )}
          </ButtonContainer>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
