import get from 'lodash/get';
import { observable } from 'mobx';

export class StateStore {
  @observable
  salesforceLeadId = undefined;

  @observable
  salesforceOpportunityId = undefined;

  salesforceContactId = undefined;
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  quoteRef = '';

  @observable
  created = false;

  @observable
  rateLoader = false;

  @observable
  isSalesforceWorking = true;

  saveSalesforceData = quote => {
    const data = get(quote, 'transactionData.dataPoints');
    const Quote = get(data, '[0].dataPointData');
    const Policyholder = get(Quote, 'Policyholder[0]');
    this.firstName = get(Policyholder, 'FirstName');
    this.lastName = get(Policyholder, 'LastName');
    this.email = get(Policyholder, 'Email');
    this.phone = get(Policyholder, 'Telephone');
    if (quote.salesforceOpportunityId) {
      this.salesforceOpportunityId = quote.salesforceOpportunityId;
    }
    if (quote.salesforceContactId) {
      this.salesforceContactId = quote.salesforceContactId;
    }
    this.quoteRef = quote.quoteRef;
    this.salesforceLeadId = quote.leadId;
    this.salesforceContactId = quote.contactId;
    this.salesforceOpportunityId = quote.opportunityId;
    this.setCreated(true);
  };

  saveDataToFormik = formik => {
    const { setValues, values } = formik;
    const updateData = {
      firstName: this.firstName || values.firstName,
      lastName: this.lastName || values.lastName,
      email: this.email || values.email,
      phone: this.phone || values.phone
    };

    setValues({
      ...values,
      ...updateData
    });
  };

  getCreated = () => {
    return this.created;
  };
  setCreated = value => {
    this.created = value;
  };
  getIsSalesforceWorking = () => {
    return this.isSalesforceWorking;
  };
  setIsSalesforceWorking = value => {
    this.isSalesforceWorking = value;
  };
  getRateLoader = () => {
    return this.rateLoader;
  };
}

export const salesforceStore = new StateStore();
