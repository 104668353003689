import React from 'react';
import { css } from 'emotion';
import { useHistory } from 'react-router-dom';
import { stepStore } from '../../store/step-store';
import { CustomButton } from '../components/CustomButton';
import { ButtonContainer } from '../components/ButtonContainer';
import { Container } from '../components/Container';
import errorIcon from '../../assets/errorIcon.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { quoteStore } from '../../store/quote-store';
import { salesforceStore } from '../../store/salesforce-store';

const checkCircleStyle = css`
  height: 8rem;
  width: 8rem;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: 8rem 8rem;
  align-self: center;
  margin: 3rem 0;
`;
const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;
const pageContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
`;
const textStyle = css`
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--dark);
`;
const pageWidth = css`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'progress main main';
  background-color: var(--white);
  height: 100%;
  flex: 1;
  max-height: calc(100vh - 80px);

  @media (min-width: 1201px) {
    max-height: calc(100vh - 121px);
    grid-template-areas: 'progress main info';
  }

  @media (max-width: 768px) {
    grid-template-areas: 'main';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;
export const ErrorPage = () => {
  const history = useHistory();
  return (
    <div className="App">
      <Header />
      <div className={pageWidth}>
        <Container>
          <div className={pageContainer}>
            <div className={contentContainer}>
              <div className={checkCircleStyle} />
              <h1 className={textStyle}>{quoteStore.apiError ? quoteStore.apiError : 'Something went wrong.'}</h1>
            </div>
          </div>
          <ButtonContainer>
            <CustomButton
              fullWidth
              handleClick={() => {
                if (salesforceStore.salesforceOpportunityId) {
                  history.replace(`/opportunity/${salesforceStore.salesforceOpportunityId}`);
                } else if (salesforceStore.salesforceLeadId) {
                  history.replace(`/lead/${salesforceStore.salesforceLeadId}`);
                } else {
                  history.replace('/');
                }
                stepStore.step = window.innerWidth < 768 ? 0 : 1;
                history.go(0);
              }}
              label="BACK TO HOME"
            />
          </ButtonContainer>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
