export const initialValues = {
  PractitionerProfessionClinical: { code: '', description: '' },
  PractitionerLicence: '',
  LimitOfIndemnity: { description: '', code: '' },
  Turnover: undefined,
  AdditionalPractitioners: '',
  NumberOfAdditionalPractitioners: 1,
  practitioner: [],
  EffectivePeriodStartDate: new Date(),
  legalAddOn: false,
  excessProtection: 'None',
  promotionalCode: '',
  partnershipReferralCode: '',
  partnershipReferralName: '',
  title: {
    code: '',
    description: ''
  },
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  address: {
    postcode: '',
    addressLine1: '',
    addressLine2: '',
    postTown: ''
  },
  companyName: '',
  companyRegNumber: '',
  PrevInsuranceHeld: '',
  PrevInsurerExpiryDate: '',
  legalStatus: {
    code: '',
    description: ''
  },
  yearsTrading: {
    code: '',
    description: ''
  },
  Assumptions: '',
  paymentType: '',
  payment: {
    accountName: '',
    bankName: '',
    accountNumber: '',
    sortCode: ''
  },
  stripe: {},
  changeDate: undefined,
  changeReason: undefined,
  covers: false,
  marketing: {
    sms: true,
    phone: true,
    email: true
  }
};
