import { action, extendObservable } from 'mobx';
import find from 'lodash/find';
import get from 'lodash/get';
import { CLOSE_BROTHERS_CODES, STRIPE_CODE } from '../constants/constants';
import { salesforceStore } from './salesforce-store';

const QUOTE_PRICES_PATH = 'underwrittenOptions[0].underwrittenCovers[0].price';
const RATE_ERROR_PATH = 'underwrittenOptions[0].underwrittenReferrals[0].referralText';

export const addOnCodes = {
  legalAddOn: {
    case1: 'LEGALEXPENSES',
    case2: 'LEGALEXPENSESNURSE'
  },
  excessProtection: {
    500: 'EXCESSPROTECTION500',
    1000: 'EXCESSPROTECTION1000'
  }
};
export class QuoteStore {
  constructor() {
    this.reset();
  }

  @action
  reset() {
    extendObservable(this, {
      quoteRef: undefined,
      savingQuote: false,
      policyId: undefined,
      underwritingResponseId: undefined,
      optionId: undefined,
      underwrittenPrice: {
        grossPremiumAmountIncTax: undefined,
        iptAmount: undefined,
        netPremium: undefined,
        commission: undefined,
        commissionPercentage: undefined,
        totalAmount: undefined,
        fees: undefined
      },
      stripePrice: {
        upFrontAmount: undefined,
        unequalInstalmentAmount: undefined,
        standardInstalmentAmount: undefined,
        totalAmountPayable: undefined
      },
      closeBrothersPrice: {
        upFrontAmount: undefined,
        unequalInstalmentAmount: undefined,
        standardInstalmentAmount: undefined,
        interestAmount: undefined,
        totalAmountOfCredit: undefined,
        totalAmountPayable: undefined,
        instalmentCount: undefined
      },
      excessPrice: undefined,
      paymentId: undefined,
      legalAddonCase: undefined,
      legalAddOnPrice: undefined,
      excessProtectionSelected: undefined,
      addOns: undefined,
      legalAddOnSelected: false,
      rateError: undefined,
      apiError: undefined,
      isSubmitting: false,
      rateResponse: undefined,
      savedQuoteId: null,
      quoteIsSaved: false,
      prevQuote: false,
      userId: undefined,
      allAddons: [],
      salesforceLeadId: undefined,
      salesforceOpportunityId: undefined,
      salesforceContactId: undefined,
      declines: undefined,
      referrals: undefined
    });
  }

  getSelectedAddons = () =>
    this.allAddons.filter(addon =>
      [
        this.legalAddOnSelected && addOnCodes.legalAddOn[this.legalAddonCase],
        addOnCodes.excessProtection[this.excessProtectionSelected]
      ].includes(addon.addOnCode)
    );

  getSelectedAddonCodes = () => this.getSelectedAddons()?.map(addon => addon.addOnCode);

  getExcessCodeValue = code => {
    for (const [key, value] of Object.entries(addOnCodes.excessProtection)) {
      if (value === code) {
        return key;
      }
    }
    return 'None';
  };

  parseEndorsements = quoteEndorsements => {
    if (!quoteEndorsements) {
      return undefined;
    }
    const tempArr = [];
    quoteEndorsements.forEach(x => {
      const regex = /<[o,u]l[^>]*>/g;
      const wordingPadding = x.endorsementWording?.replaceAll(regex, '<ul style="padding-left: 1.05rem;">');
      tempArr.push({
        name: x.endorsementName,
        wording: wordingPadding
      });
    });
    return tempArr;
  };
  setEndorsements = unprocessedEndorsements => {
    this.endorsements = this.parseEndorsements(unprocessedEndorsements);
  };

  saveQuoteData = quote => {
    this.rateResponse = quote;
    const { underwritingOutcome } = quote;
    this.quoteRef = quote.quoteRef;
    this.requestData = get(quote, 'requestData') || this.requestData;

    this.underwritingResponseId = get(underwritingOutcome, 'underwritingOutcomeResponseId');
    this.optionId = get(underwritingOutcome, 'underwrittenOptions[0].optionId');
    const quoteUnderwrittenPrice = get(underwritingOutcome, QUOTE_PRICES_PATH);
    this.allAddons = get(underwritingOutcome, 'underwrittenOptions[0].underwrittenAddOns');

    if (this.allAddons) {
      this.allAddons.forEach(addOn => {
        if (addOn?.selected === true) {
          if (addOn?.addOnCode === 'EXCESSPROTECTION1000') this.excessProtectionSelected = 1000;
          if (addOn?.addOnCode === 'EXCESSPROTECTION500') this.excessProtectionSelected = 500;
        }
      });
    }
    this.legalAddOn = find(
      get(underwritingOutcome, 'underwrittenOptions[0].underwrittenAddOns'),
      a => a.addOnCode === 'LEGALEXPENSES'
    );

    this.legalAddOnNurses = find(
      get(underwritingOutcome, 'underwrittenOptions[0].underwrittenAddOns'),
      a => a.addOnCode === 'LEGALEXPENSESNURSE'
    );
    this.legalAddonCase = this.legalAddOn ? 'case1' : 'case2';

    const stripe = find(
      get(underwritingOutcome, 'underwrittenOptions[0].paymentCollectionOptions'),
      a => a.code === STRIPE_CODE
    );

    this.legalAddOnPrice = this.legalAddOnNurses?.selected ? '199.00' : this.legalAddOn?.selected ? '59.00' : '0.00';
    this.excessPrice =
      this.excessProtectionSelected === 500 ? '70.00' : this.excessProtectionSelected === 1000 ? '137.00' : '0.00';
    this.underwrittenPrice = {
      grossPremiumAmountIncTax: get(quoteUnderwrittenPrice, 'grossPremiumAmountIncTax.amount'),
      iptAmount: get(quoteUnderwrittenPrice, 'iptAmount.amount'),
      netPremium: get(quoteUnderwrittenPrice, 'netPremium.amount'),
      commission: get(quoteUnderwrittenPrice, 'commission.amount'),
      commissionPercentage: get(quoteUnderwrittenPrice, 'commissionPercentage'),
      totalAmount: get(stripe, 'totalAmountPayable.amount'),
      fees: get(underwritingOutcome, 'underwrittenOptions[0].underwrittenDataPoints[0].underwrittenPrice.fees.amount')
    };

    this.stripePrice = {
      upFrontAmount: get(stripe, 'upFrontAmount.amount'),
      unequalInstalmentAmount: get(stripe, 'unequalInstalmentAmount.amount'),
      standardInstalmentAmount: get(stripe, 'standardInstalmentAmount.amount'),
      totalAmountPayable: get(stripe, 'totalAmountPayable.amount')
    };

    const closeBrothers = find(get(underwritingOutcome, 'underwrittenOptions[0].paymentCollectionOptions'), a =>
      CLOSE_BROTHERS_CODES.includes(a.code)
    );
    this.closeBrothersPrice = {
      code: get(closeBrothers, 'code'),
      upFrontAmount: get(closeBrothers, 'upFrontAmount.amount'),
      unequalInstalmentAmount: get(closeBrothers, 'unequalInstalmentAmount.amount'),
      standardInstalmentAmount: get(closeBrothers, 'standardInstalmentAmount.amount'),
      interestAmount: get(closeBrothers, 'interestAmount.amount'),
      totalAmountOfCredit: get(closeBrothers, 'totalAmountOfCredit.amount'),
      totalAmountPayable: get(closeBrothers, 'totalAmountPayable.amount'),
      instalmentCount: get(closeBrothers, 'instalmentCount')
    };
    if (quote.salesforceOpportunityId) {
      this.salesforceOpportunityId = quote.salesforceOpportunityId;
      salesforceStore.salesforceOpportunityId = quote.salesforceOpportunityId;
    }
    if (quote.salesforceContactId) {
      this.salesforceContactId = quote.salesforceContactId;
      salesforceStore.salesforceContactId = quote.salesforceContactId;
    }

    this.endorsements = this.parseEndorsements(
      get(underwritingOutcome, 'underwrittenOptions[0].underwrittenEndorsements')
    );

    this.declines = get(underwritingOutcome, 'underwrittenOptions[0].underwrittenDeclines');
    this.referrals = get(underwritingOutcome, 'underwrittenOptions[0].underwrittenReferrals');
    this.rateError = get(underwritingOutcome, RATE_ERROR_PATH);
    this.isSubmitting = false;
  };
}

export const quoteStore = new QuoteStore();
