import React, { useRef } from 'react';
import { Formik } from 'formik';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { initialValues } from '../../validation/initialValues';
import { editValidationScheme, validationScheme } from '../../validation/validationScheme';
import { stepStore } from '../../store/step-store';
import { LeadWrapper } from './LeadWrapper';
import { QuoteWrapper } from './QuoteWrapper';
import { MtaWrapper } from './MtaWrapper';
import { OpportunityWrapper } from './OpportunityWrapper';
import { stateStore } from '../../store/state-store';
import { AppForm } from '../AppForm/AppForm';
import { RenewalWrapper } from './RenewalWrapper';

export const FormikWrapper = observer(() => {
  const formikRef = useRef();
  const handleFormikSubmit = (_, formik) => {
    if (stepStore.step < 9 && stateStore.inEditMode) {
      stepStore.goToStep('chooseStep');
    } else if (stepStore.step < 9 && stateStore.inRenewalMode) {
      stepStore.goToStep('renewalPage');
    } else {
      stepStore.nextStep();
    }
    formik.setTouched({}, false);
    window.scrollTo(0, 0);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={stateStore.inEditMode ? editValidationScheme[stepStore.step] : validationScheme[stepStore.step]}
      onSubmit={handleFormikSubmit}
    >
      {formik => {
        formikRef.current = formik;
        return (
          <Switch>
            <Route exact path="/lead/:id" component={LeadWrapper} />
            <Route exact path="/opportunity/:id" component={OpportunityWrapper} />
            <Route exact path="/quote/:id/userId/:userid" component={QuoteWrapper} />
            <Route exact path="/policy/:id/userId/:userid" component={MtaWrapper} />
            <Route exact path="/renewal/:id/userId/:userid" component={RenewalWrapper} />
            <Route route="/form" component={AppForm} />
          </Switch>
        );
      }}
    </Formik>
  );
});
