import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuoteInfoFromOpportunityID } from '../../api/api';
import { AppForm } from '../AppForm/AppForm';
import { Loader } from '../components/Loader';

export const OpportunityWrapper = () => {
  const { id } = useParams();
  const { isLoading: isLoadingQuote } = useQuoteInfoFromOpportunityID(id);
  if (isLoadingQuote) {
    return <Loader message="Loading your quote..." />;
  }

  return <AppForm />;
};
