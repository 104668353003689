import { get } from 'lodash';
import { quoteStore } from '../store/quote-store';

export const getFormattedPrice = value => {
  if (!value) {
    return '£0.00';
  }
  const newPrice = parseFloat(value).toFixed(2);
  return newPrice < 0 ? `-£${Math.abs(newPrice).toFixed(2)}` : `£${newPrice}`;
};

// eslint-disable-next-line consistent-return
export const checkVisibility = (id, breakdown = true) => {
  if (document.getElementById(id)) {
    const fieldValues = document.getElementById(id).getBoundingClientRect();
    if (window.innerWidth < 769) {
      return (
        fieldValues.top < 0 || fieldValues.bottom >= (breakdown ? window.innerHeight - 170 : window.innerWidth - 85)
      );
    }
    return fieldValues.top < 0 || fieldValues.bottom >= window.innerHeight - 85;
  }
};

export const populateLegalAddons = (formik, data) => {
  const { values, setValues } = formik;

  const underwrittenAddOns = get(data, 'underwritingOutcome.underwrittenOptions[0].underwrittenAddOns');

  let excessProtection = 'None';
  let legalAddOn = false;
  if (underwrittenAddOns) {
    underwrittenAddOns.forEach(addOn => {
      if (addOn?.selected === true) {
        if (addOn?.addOnCode === 'EXCESSPROTECTION1000') excessProtection = 1000;
        if (addOn?.addOnCode === 'EXCESSPROTECTION500') excessProtection = 500;
        if (addOn?.addOnCode === 'LEGALEXPENSES') legalAddOn = true;
      }
    });
  }

  quoteStore.legalAddOnSelected = legalAddOn;
  quoteStore.excessProtectionSelected = excessProtection;
  setValues({ ...values, legalAddOn, excessProtection });
};
