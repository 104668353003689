import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuote } from '../../api/api';
import { AppForm } from '../AppForm/AppForm';
import { Loader } from '../components/Loader';
import { quoteStore } from '../../store/quote-store';
import { salesforceStore } from '../../store/salesforce-store';

export const QuoteWrapper = () => {
  const { id, userid } = useParams();
  const { isLoading: isLoadingQuote } = useQuote(id, userid);
  quoteStore.userId = userid;
  salesforceStore.setCreated(true);
  if (isLoadingQuote) {
    return <Loader message="Please wait." />;
  }

  return <AppForm />;
};
