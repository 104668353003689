import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuoteInfoFromLeadID } from '../../api/api';
import { AppForm } from '../AppForm/AppForm';
import { Loader } from '../components/Loader';

export const LeadWrapper = () => {
  const { id } = useParams();
  const { isLoading: isLoadingQuote } = useQuoteInfoFromLeadID(id);
  if (isLoadingQuote) {
    return <Loader message="Loading your quote..." />;
  }

  return <AppForm />;
};
