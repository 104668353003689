import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import { stepStore } from '../../store/step-store';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { SalesforceDetails } from '../Salesforce/SalesforceDetails';
import { FormikWrapper } from './FormikWrapper';
import { stateStore } from '../../store/state-store';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: calc(100% - 121px);
  @media (min-width: 320px) and (max-width: 1024px) {
    min-height: calc(100% - 80px);
  }
`;

const hasQuoteStyle = css`
  min-height: calc(100% - 121px);
  @media (max-width: 768px) {
    min-height: calc(100% - 80px - 5.3rem);
  }
`;

const expandedStyle = css`
  min-height: calc(100% - 121px);
  @media (min-width: 320px) and (max-width: 1024px) {
    min-height: calc(100% - 80px);
  }
  @media (min-width: 769px) {
    height: auto;
  }
`;

const Main = observer(() => {
  const getExpandedStyle = () => [1, 'chooseStep'].includes(stepStore.step) && expandedStyle;
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === '/form') {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <Header />
      <main
        className={cx(
          containerStyle,
          getExpandedStyle(),
          ([7, 8].includes(stepStore.step) && !stateStore.inEditMode) || [(9, 10)].includes(stepStore.step)
            ? hasQuoteStyle
            : ''
        )}
      >
        <Switch>
          <Route exact path="/" component={SalesforceDetails} />
          <Route path="/" component={FormikWrapper} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
});

export default Main;
