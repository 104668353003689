import * as yup from 'yup';
import moment from 'moment';

yup.addMethod(yup.string, 'isDate', function(errorMessage) {
  return this.test({
    name: 'idDate',
    message: errorMessage,
    test: value => moment(value, 'DD/MM/YYYY', true).isValid()
  });
});
