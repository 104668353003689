import { observable } from 'mobx';
import get from 'lodash/get';

const QUOTE_PRICES_PATH = 'underwrittenOptions[0].underwrittenCovers[0].price';

export class MTAStore {
  @observable
  mta = undefined;

  @observable
  policyReference = undefined;

  @observable
  policyId = undefined;

  @observable
  mtaStartDate = undefined;

  @observable
  mtaReason = undefined;

  @observable
  mtaReference = undefined;

  @observable
  underwrittenPrice = undefined;

  @observable
  paymentType = undefined;

  @observable
  step1Values = {};

  @observable
  hasCloseBrothersOption = undefined;

  @observable
  isPricePositive = undefined;

  @observable
  declines = undefined;

  @observable
  referrals = undefined;

  saveQuoteData = data => {
    this.policyId = get(data, 'mtaMetadata.policyReference');
    this.mtaReference = get(data, 'transactionId');

    const { underwritingOutcome } = data;
    const quoteUnderwrittenPrice = get(underwritingOutcome, QUOTE_PRICES_PATH);
    this.underwrittenPrice = {
      grossPremiumAmountIncTax: get(quoteUnderwrittenPrice, 'grossPremiumAmountIncTax.difference'),
      iptAmount: get(quoteUnderwrittenPrice, 'iptAmount.difference'),
      netPremium: get(quoteUnderwrittenPrice, 'netPremium.difference'),
      commission: get(quoteUnderwrittenPrice, 'commission.difference'),
      commissionPercentage: get(quoteUnderwrittenPrice, 'commissionPercentage'),
      fees: get(underwritingOutcome, 'underwrittenOptions[0].underwrittenDataPoints[0].underwrittenPrice.fees.amount')
    };

    this.declines = get(underwritingOutcome, 'underwrittenOptions[0].underwrittenDeclines');
    this.referrals = get(underwritingOutcome, 'underwrittenOptions[0].underwrittenReferrals');

    const paymentCodes = get(data, 'underwritingOutcome.underwrittenOptions[0].paymentCollectionOptions');
    this.hasCloseBrothersOption = paymentCodes
      ? paymentCodes.some(payment => payment.code === 'INSTALMENT_ADJUSTMENT_FINANCE_HOUSE')
      : undefined;
    this.isPricePositive = parseFloat(get(this.underwrittenPrice, 'grossPremiumAmountIncTax')) > 0;
  };
}

export const mtaStore = new MTAStore();
