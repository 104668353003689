export class LookupStore {
  titles = [];

  legalStatuses = [];

  mtaReason = [];

  tradingAs = [];

  axisYearsTrading = [];

  axisPractitionerProfession = [];

  axisLimitOfIndemnity = [];

  lastChangeDate = null;

  renewalStartDate = null;
}

export const lookupStore = new LookupStore();
