import { css } from 'emotion';
import React from 'react';

const containerStyle = css`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 1rem 1rem 0;
  height: 100%;
  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 1rem 2rem 0;
  }
`;

export const Container = props => <div className={containerStyle}>{props.children}</div>;
