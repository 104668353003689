import { observable } from 'mobx';

export class StepStore {
  @observable
  step = 1;

  @observable
  previousStepValue = [0];

  nextStep = () => {
    this.previousStepValue.push(this.step);
    this.step++;
  };

  // eslint-disable-next-line no-return-assign
  previousStep = () => (this.step = this.previousStepValue.pop());

  skipNSteps = n => {
    this.previousStepValue.push(this.step);
    this.step += n;
  };

  returnNSteps = n => {
    this.previousStepValue = this.previousStepValue.slice(0, 1 - n);
    this.step = this.previousStepValue.pop();
  };

  goToStep = n => {
    this.previousStepValue.push(this.step);
    this.step = n;
  };
}

export const stepStore = new StepStore();
