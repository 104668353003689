/* eslint-disable no-return-assign */
import { observable } from 'mobx';

export class StateStore {
  @observable
  inEditMode = false;

  @observable
  inRenewalMode = false;

  @observable
  isQuoteSaved = false;

  @observable
  isPastPrice = false;

  setInEditMode = mode => (this.inEditMode = mode);
  setInRenewalMode = mode => (this.inRenewalMode = mode);
  setIsQuoteSaved = saved => (this.isQuoteSaved = saved);
}

export const stateStore = new StateStore();
