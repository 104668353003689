import { observer } from 'mobx-react';
import React from 'react';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';
import { CustomButton } from '../components/CustomButton';
import { Container } from '../components/Container';
import infoImg from '../../assets/savedQuoteIcon.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { ButtonContainer } from '../components/ButtonContainer';
import { quoteStore } from '../../store/quote-store';
import { stepStore } from '../../store/step-store';

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;
const pageContainer = css`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'progress main main';
  background-color: var(--white);
  height: 100%;
  flex: 1;
  max-height: calc(100vh - 80px);

  @media (min-width: 1201px) {
    max-height: calc(100vh - 121px);
    grid-template-areas: 'progress main info';
  }

  @media (max-width: 768px) {
    grid-template-areas: 'main';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;
const textStyle = css`
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: flex;
  align-items: flex-end;
  text-align: center;
  align-self: center;
  letter-spacing: -0.03em;
  color: var(--dark);
`;
const imageStyle = css`
  height: 8rem;
  width: 8rem;
  margin-right: 1rem;
  align-self: center;
  margin-bottom: 3rem;
`;

const quoteRefStyle = css`
  color: var(--primaryBrand);
  font-weight: bold;
`;

const centeredTextStyle = css`
  font-family: Lato, sans-serif;
  text-align: center;
  margin: 1rem 0rem;
  font-size: 1rem;
`;

export const SavedQuote = withRouter(
  observer(props => {
    const backHandler = () => {
      if (quoteStore.prevQuote) {
        window.location.href = `${process.env.REACT_APP_CUSTOMER_PORTAL}/quotes/${quoteStore.prevQuote}`;
      } else {
        quoteStore.reset();
        stepStore.step = 1;
        props.history.replace('/');
      }
    };

    return (
      <div className="App">
        <Header />
        <div className={pageContainer}>
          <Container>
            <div className={contentContainer}>
              <img src={infoImg} className={imageStyle} alt="info" />
              <h1 className={textStyle}>
                {quoteStore.prevQuote ? 'Your quote has been updated' : 'Your quote has been saved'}
              </h1>
              <p className={centeredTextStyle}>
                Your quote reference is:
                <span className={quoteRefStyle}> {quoteStore.quoteRef}</span>
                <p className={centeredTextStyle}>
                  You will receive an email shortly with how you can manage your quote. If you need to make a change to
                  your quote or view your quote documents you can do this from our customer portal before purchasing.
                </p>
              </p>
            </div>
            <ButtonContainer>
              <CustomButton
                handleClick={backHandler}
                fullWidth
                label={quoteStore.prevQuote ? 'BACK TO CUSTOMER PORTAL' : 'BACK TO HOME'}
              />
            </ButtonContainer>
          </Container>
        </div>
        <Footer />
      </div>
    );
  })
);
