import { observable } from 'mobx';
import get from 'lodash/get';
import { find } from 'lodash';
import { CLOSE_BROTHERS_CODES, STRIPE_CODE } from '../constants/constants';

const QUOTE_PRICES_PATH = 'underwrittenOptions[0].underwrittenCovers[0].price';

export class RenewalStore {
  @observable
  renewal = undefined;

  @observable
  rateResponse = undefined;

  @observable
  policyReference = undefined;

  @observable
  policyId = undefined;

  @observable
  payments = undefined;

  @observable
  renewalTransaction = undefined;

  @observable
  underwrittenPrice = undefined;

  @observable
  paymentType = undefined;

  @observable
  optionId = undefined;

  @observable
  closeBrothersPrice = {
    code: undefined,
    upFrontAmount: undefined,
    unequalInstalmentAmount: undefined,
    standardInstalmentAmount: undefined,
    interestAmount: undefined,
    totalAmountOfCredit: undefined,
    totalAmountPayable: undefined,
    instalmentCount: undefined
  };

  @observable
  step1Values = {};

  @observable
  hasCloseBrothersOption = undefined;

  saveQuoteData = data => {
    this.rateResponse = data;
    this.policyId = get(data, 'policyMetadata.policyReference');
    this.renewalTransaction = get(data, 'renewalMetadata.transactionId');

    const { underwritingOutcome } = data;
    const stripe = find(
      get(underwritingOutcome, 'underwrittenOptions[0].paymentCollectionOptions'),
      a => a.code === STRIPE_CODE
    );
    const quoteUnderwrittenPrice = get(underwritingOutcome, QUOTE_PRICES_PATH);
    this.underwrittenPrice = {
      grossPremiumAmountIncTax: get(quoteUnderwrittenPrice, 'grossPremiumAmountIncTax.difference'),
      iptAmount: get(quoteUnderwrittenPrice, 'iptAmount.difference'),
      netPremium: get(quoteUnderwrittenPrice, 'netPremium.difference'),
      commission: get(quoteUnderwrittenPrice, 'commission.difference'),
      commissionPercentage: get(quoteUnderwrittenPrice, 'commissionPercentage'),
      totalAmount: get(stripe, 'totalAmountPayable.amount'),
      fees: get(underwritingOutcome, 'underwrittenOptions[0].underwrittenDataPoints[0].underwrittenPrice.fees.amount')
    };
    this.optionId = get(data, 'underwritingOutcome.underwrittenOptions[0].optionId');

    const paymentCodes = get(data, 'underwritingOutcome.underwrittenOptions[0].paymentCollectionOptions');
    const closeBrothers = find(get(data, 'underwritingOutcome.underwrittenOptions[0].paymentCollectionOptions'), a =>
      CLOSE_BROTHERS_CODES.includes(a.code)
    );
    this.closeBrothersPrice = {
      code: get(closeBrothers, 'code'),
      upFrontAmount: get(closeBrothers, 'upFrontAmount.amount'),
      unequalInstalmentAmount: get(closeBrothers, 'unequalInstalmentAmount.amount'),
      standardInstalmentAmount: get(closeBrothers, 'standardInstalmentAmount.amount'),
      interestAmount: get(closeBrothers, 'interestAmount.amount'),
      totalAmountOfCredit: get(closeBrothers, 'totalAmountOfCredit.amount'),
      totalAmountPayable: get(closeBrothers, 'totalAmountPayable.amount'),
      instalmentCount: get(closeBrothers, 'instalmentCount')
    };
    this.payments = paymentCodes;
    this.hasCloseBrothersOption = paymentCodes
      ? paymentCodes.some(payment => payment.code === 'INSTALMENT_ADJUSTMENT_FINANCE_HOUSE')
      : undefined;
  };
}

export const renewalStore = new RenewalStore();
