import { css, cx } from 'emotion';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { stateStore } from '../../store/state-store';
import { stepStore } from '../../store/step-store';
import { ProgressBar } from '../components/ProgressBar';
import { Container } from '../components/Container';
import { Loader } from '../components/Loader';
import { useLookups } from '../../api/api';
import { salesforceStore } from '../../store/salesforce-store';
import { useFirebase } from '../Firebase';

const AdditionalPractitioners = lazy(() => import('./components/AdditionalPractitioners'));
const AnnualIncome = lazy(() => import('./components/AnnualIncome'));
const Assumptions = lazy(() => import('./components/Assumptions'));
const BusinessDetails = lazy(() => import('./components/BusinessDetails'));
const IndemnityPage = lazy(() => import('./components/IndemnityPage'));
const QualificationPage = lazy(() => import('./components/QualificationPage'));
const StartDate = lazy(() => import('./components/StartDate'));
const QuoteValue = lazy(() => import('./components/QuoteValue'));
const ChangeDate = lazy(() => import('./components/ChangeDate'));
const ChangeReason = lazy(() => import('./components/ChangeReason'));
const ChooseStep = lazy(() => import('./components/ChooseStep'));
const PaymentDetails = lazy(() => import('./components/PaymentDetails'));
const PersonalDetails = lazy(() => import('./components/PersonalDetails'));
const StripeWrapper = lazy(() => import('../components/StripeWrapper'));
const QuoteDetails = lazy(() => import('../components/QuoteDetails'));
const RenewalPage = lazy(() => import('./components/RenewalPage'));

const containerStyle = css`
  display: grid;
  grid-template-columns: 2fr 7fr;
  grid-template-areas:
    'info progress'
    'info main';
  grid-template-rows: minmax(2rem, min-content) minmax(auto, 100%);
  background-color: var(--white);
  height: 100%;
  min-height: calc(100vh - 121px);
  flex: 1;

  @media (min-width: 1201px) {
    grid-template-columns: 2fr 5fr 2fr;
    grid-template-areas:
      'aside progress info'
      'aside main info';
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    min-height: calc(100vh - 80px);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'progress'
      'main'
      'info';
    grid-template-rows: 40px auto min-content;
  }
`;

function removeSensitiveInfo(values) {
  delete values['email'];
  delete values['phone'];
  delete values['dateOfBirth'];
  delete values['address'];
  delete values['companyName'];
  delete values['paymentType'];
  delete values['payment'];
  delete values['companyRegNumber'];
  return values;
}

export const AppForm = observer(() => {
  const formik = useFormikContext();
  const { isLoading } = useLookups();
  const { step } = stepStore;
  const { inEditMode, inRenewalMode, isQuoteSaved } = stateStore;
  const firebase = useFirebase();
  const formikRef = useRef();
  formikRef.current = formik;
  const history = useHistory();

  useEffect(() => {
    function saveData() {
      if (
        (salesforceStore.salesforceLeadId || salesforceStore.salesforceOpportunityId) &&
        !(inEditMode || inRenewalMode || isQuoteSaved) &&
        !localStorage.getItem(salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId)
      ) {
        localStorage.setItem(
          salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId,
          JSON.stringify({
            salesforceId: salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId,
            values: JSON.stringify(removeSensitiveInfo(Object.assign({}, formikRef.current.values))),
            storeValues: {
              isPastPrice: stateStore.isPastPrice,
              step: stepStore.step,
              previousStepValue: stepStore.previousStepValue
            }
          })
        );
        localStorage.setItem(
          'previousQuote',
          JSON.stringify({
            leadId: salesforceStore.salesforceLeadId,
            opportunityId: salesforceStore.salesforceOpportunityId,
            timestamp: new Date().getTime(),
            storeValues: {
              isPastPrice: stateStore.isPastPrice,
              step: stepStore.step,
              previousStepValue: stepStore.previousStepValue
            }
          })
        );
        firebase.doSaveFormData({
          salesforceId: salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId,
          values: JSON.stringify(removeSensitiveInfo(Object.assign({}, formikRef.current.values))),
          storeValues: {
            isPastPrice: stateStore.isPastPrice,
            step: stepStore.step,
            previousStepValue: stepStore.previousStepValue
          }
        });
      }

      if ((salesforceStore.salesforceLeadId || salesforceStore.salesforceOpportunityId) && isQuoteSaved) {
        localStorage.setItem('previousQuote', '');
      }
    }

    history.listen((location, action) => {
      if (location.pathname !== '/error' && location.pathname !== '/') return;
      saveData();
    });

    function handleBeforeUnload(event) {
      event.preventDefault();
      saveData();
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBeforeUnload);
    };
  }, [firebase, history, inEditMode, inRenewalMode, isQuoteSaved]);

  const handleNextStep = () => {
    formik.handleSubmit();

    if (!formik.values.firstName) {
      salesforceStore.saveDataToFormik(formik);
    }
  };

  const handlePreviousStep = () => {
    if (inEditMode) {
      stepStore.goToStep('chooseStep');
    } else if (inRenewalMode) {
      stepStore.goToStep('renewalPage');
    } else {
      stepStore.previousStep();
    }
    window.scrollTo(0, 0);
  };
  if (isLoading) {
    return <Loader message="Please wait..." />;
  }

  return (
    <div className={cx(containerStyle)}>
      {!(inEditMode || inRenewalMode) && <ProgressBar />}
      <Suspense
        fallback={
          <Container>
            <Loader message="Please wait..." />
          </Container>
        }
      >
        {step === 'changeDate' && <ChangeDate />}
        {step === 'changeReason' && <ChangeReason />}
        {step === 'chooseStep' && <ChooseStep />}
        {step === 'renewalPage' && <RenewalPage />}
        {step === 1 && <QualificationPage handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
        {step === 2 && <IndemnityPage handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
        {step === 3 && (
          <AdditionalPractitioners handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
        )}
        {step === 4 && <AnnualIncome handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
        {step === 5 && <StartDate handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
        {step === 6 && <QuoteValue handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
        {step === 7 &&
          (inEditMode || inRenewalMode ? (
            <PersonalDetails handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
          ) : (
            <>
              <PersonalDetails handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
              <QuoteDetails />
            </>
          ))}
        {step === 8 &&
          (inEditMode || inRenewalMode ? (
            <BusinessDetails handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
          ) : (
            <>
              <BusinessDetails handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
              <QuoteDetails />
            </>
          ))}
        {step === 9 && (
          <>
            <Assumptions handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
            <QuoteDetails />
          </>
        )}
        {step === 10 && (
          <>
            <StripeWrapper>
              <PaymentDetails handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
            </StripeWrapper>
            <QuoteDetails />
          </>
        )}
      </Suspense>
    </div>
  );
});
