import moment from 'moment';
import * as yup from 'yup';
import './customYupValidations';
import { CLOSE_BROTHERS_CODES } from '../constants/constants';

export const validationScheme = [
  yup.object().shape({}),
  yup.object().shape({
    PractitionerProfessionClinical: yup
      .object()
      .shape({
        code: yup.string().required('Qualification/Profession is required'),
        description: yup.string().required('Qualification/Profession is required')
      })
      .required('Qualification/Profession is required'),
    PractitionerLicence: yup.string().when('PractitionerProfessionClinical', {
      is: value => !['PHLEBOTOMIST', 'CAREWORKER', 'FIRSTAIDER', 'HEALTHCAREASSISTANTCAREWORKER'].includes(value.code),
      then: yup.string().required('License information is required'),
      otherwise: yup.string()
    })
  }),
  yup.object().shape({
    LimitOfIndemnity: yup.object().shape({
      code: yup.string().required('Indemnity Limit is required'),
      description: yup.string().required('Indemnity Limit is required')
    })
  }),
  yup.object().shape({
    AdditionalPractitioners: yup.string().required('Additional Practitioners information is required'),

    NumberOfAdditionalPractitioners: yup.number().when('AdditionalPractitioners', {
      is: value => value === 'false',
      then: yup.number(),
      otherwise: yup.number().required('Number of partners is a required field')
    }),
    practitioner: yup
      .array()
      .when('AdditionalPractitioners', {
        is: value => value === 'false',
        then: yup.array(),
        otherwise: yup.array().of(
          yup.object().shape({
            firstName: yup.string().required('First name is a required field'),
            lastName: yup.string().required('Last name is a required field'),
            title: yup.object().shape({
              code: yup.string().required('Title is a required field'),
              description: yup.string().required('Title is a required field')
            })
          })
        )
      })
      .when('AdditionalPractitioners', {
        is: value => value === 'false',
        then: yup.array(),
        otherwise: yup
          .array()
          .when('NumberOfAdditionalPractitioners', (NumberOfAdditionalPractitioners, schema) =>
            schema.min(NumberOfAdditionalPractitioners)
          )
          .required('You have to add at least one additional partner')
      })
  }),
  yup.object().shape({
    Turnover: yup
      .number()
      .required('Annual Income is a required field')
      .min(1, 'Annual Income should be at least £1')
      .max(100000, 'Max value can be 100 000')
  }),
  yup.object().shape({
    EffectivePeriodStartDate: yup
      .string() // todo
      .required('Cover start date is a required field')
  }),
  yup.object().shape({}),
  yup.object().shape({
    title: yup.object().shape({
      code: yup.string().required('Title is a required field'),
      description: yup.string().required('Title is a required field')
    }),
    firstName: yup.string().required('First name is a required field'),
    lastName: yup.string().required('Last name is a required field'),
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    phone: yup.string().required('Phone is a required field'),
    dateOfBirth: yup
      .string()
      .required('Date of birth is a required field')
      .isDate('Date of Birth must have a valid date value')
      .test(
        'isAdult',
        'Unfortunately we cannot provide a quote as you are under the age of 18',
        value => moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') >= 18
      )
      .test(
        'isInvalidAge',
        'Unfortunately we cannot provide a quote as your age is out of accepted range.',
        value => moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') <= 120
      ),
    address: yup.object().shape({
      addressLine1: yup.string().required('Address Line 1 is a required field'),
      postTown: yup.string().required('City is a required field'),
      postcode: yup.string().required('Postcode is a required field')
    })
  }),
  yup.object().shape({
    companyName: yup.string().required('Business name is a required field'),
    companyRegNumber: yup.string().when('legalStatus', {
      is: value => ['001', '011'].includes(value.code),
      then: yup.string().required('Company Registration Number is required field'),
      otherwise: yup.string()
    }),
    legalStatus: yup.object().shape({
      code: yup.string().required('Business Type is a required field'),
      description: yup.string().required('Business Type is a required field')
    }),
    yearsTrading: yup.object().when('legalStatus', {
      is: value => value.code === '001',
      then: yup.object().shape({
        code: yup.string().required('Years Trading is a required field'),
        description: yup.string().required('Years Trading is a required field')
      })
    }),
    PrevInsuranceHeld: yup.string().required('Existing Medical Liability Cover Information is a required field'),
    PrevInsurerExpiryDate: yup.string().when('PrevInsuranceHeld', {
      is: value => value === 'false',
      then: yup.string(),
      otherwise: yup
        .string()
        .required('Existing Liability Insurance Start Date is required.')
        .isDate('Existing Liability Insurance Start Date must have a valid date value')
        .test(
          'isValidDateInPast',
          'Please select a date in the past',
          value => moment().diff(moment(value, 'DD/MM/YYYY', true), 'days') >= 0
        )
    })
  }),
  yup.object().shape({
    Assumptions: yup
      .boolean()
      .required('Assumptions is a required field')
      .oneOf([true], 'You have to agree with the assumptions to continue')
  }),
  yup.object().shape({
    // stripe: yup.object().shape({
    //   authorisationId: yup.string().required('Card number is a required field'),
    // }),
    payment: yup.object().when('paymentType', {
      is: value => CLOSE_BROTHERS_CODES.includes(value),
      then: yup.object().shape({
        accountName: yup.string().required('Account holder name is a required field'),
        bankName: yup.string().required('Bank name is a required field'),
        accountNumber: yup.string().required('Account number is a required field'),
        sortCode: yup.string().required('Sort code is a required field')
      })
    })
  })
];

const editValidationSchemaTmp = [...validationScheme];

editValidationSchemaTmp.changeDate = yup.object().shape({
  changeDate: yup.date().required('Date you want your changes to be applied is required field')
});
editValidationSchemaTmp[8] = yup.object().shape({
  companyName: yup.string().required('Business name is required field'),
  companyRegNumber: yup.string().when('legalStatus', {
    is: value => ['001', '011'].includes(value.code),
    then: yup.string().required('Company Registration Number is required field'),
    otherwise: yup.string()
  }),
  legalStatus: yup.object().shape({
    code: yup.string().required('Business Type is required field'),
    description: yup.string().required('Business Type is required field')
  }),
  PrevInsuranceHeld: yup.string()
});

editValidationSchemaTmp.changeReason = yup.object().shape({
  changeReason: yup.string().required('The reason for changing your policy is required.')
});

editValidationSchemaTmp.salesforce = yup.object().shape({
  firstName: yup.string().required('First name is required field'),
  lastName: yup.string().required('Last name is required field'),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required field'),
  phone: yup.string().required('Phone is required field'),
  companyName: yup.string().required('Business name is required field')
});

export const editValidationScheme = editValidationSchemaTmp;
