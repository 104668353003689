import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppForm } from '../AppForm/AppForm';
import { stepStore } from '../../store/step-store';
import { mtaStore } from '../../store/mta-store';
import { stateStore } from '../../store/state-store';
import { useGetPolicyDate } from '../../api/api';
import { Loader } from '../components/Loader';
import { quoteStore } from '../../store/quote-store';
import { salesforceStore } from '../../store/salesforce-store';

export const MtaWrapper = () => {
  const { id, userid } = useParams();
  mtaStore.policyReference = id;
  quoteStore.userId = userid;
  const { isLoading, mutate: getPolicy } = useGetPolicyDate();

  salesforceStore.setCreated(true);

  useEffect(() => {
    getPolicy(id);
    stepStore.goToStep('changeDate');
    stateStore.setInEditMode(true);
  }, [id, getPolicy]);
  if (isLoading) {
    return <Loader message="Please wait." />;
  }

  return <AppForm />;
};
