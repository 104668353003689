import React, { useCallback } from 'react';
import { Input, InputGroupAddon, Button, InputGroup } from 'reactstrap';
import { css, cx } from 'emotion';
import debounce from 'lodash/debounce';
import { useFormikField } from '../../utils/use-formik-field';
import { RequiredError } from './RequiredError';

const inputContainerStyle = css`
  margin: 5px 0 1rem 0;
`;

const errorBorderStyle = css`
  ~ .borderDiv {
    outline-color: var(--error);
  }
`;

const disabledStyle = css`
  width: 100%;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  border: none;
  background-color: transparent;
  position: absolute;
  outline: none;
  color: var(--dark);
  left: 0;
  top: 0;
`;

const customInputStyle = css`
  width: 100%;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  border: none;
  background-color: transparent;
  position: absolute;
  outline: none;
  color: var(--dark);
  left: 0;
  top: 0;
  &:focus {
    + label {
      background-color: white;
      width: auto;
      color: var(--primaryBrand);
      transition: all 0.25s ease-out;
      padding: 0 6px;
      transform: translate(-0.5rem, -1.9rem);
      font-size: 0.75rem;
      z-index: 2;
    }
    ~ .borderDiv {
      outline-color: var(--primaryBrand);
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const groupButtonStyle = css`
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primaryBrand);
  border: none;
  margin-left: 1rem;
  height: 2.875rem;
  background: transparent;
  display: inline;
  &:focus {
    outline: none;
  }
`;

const addOnLabelStyle = css`
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  border-bottom: 2px solid var(--primaryBrand);
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
`;

const labelStyle = css`
  width: 90%;
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  line-height: 1rem;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  color: var(--darkGray);
  pointer-events: none;
  padding: 8px 0;
`;

const customInputContainer = css`
  position: relative;
  border: 2px solid var(--gray);
  flex: 1;
  height: 2.875rem;
`;

const hasValueState = css`
  label {
    color: var(--darkGray);
    background-color: var(--white);
    width: auto;
    transition: all 0.25s ease-out;
    padding: 0 6px;
    transform: translate(-0.5rem, -1.9rem);
    font-size: 0.75rem;
    z-index: 2;
  }
`;

const borderStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: 2px solid transparent;
  pointer-events: none;
`;

const fullWidthStyle = css`
  width: 100%;
`;

const sfMarginStyle = css`
  margin: 0.625rem 0;
`;

const sfInputStyle = css`
  background-color: var(--white);
  border-radius: 2px;
  color: #333;
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 0.375rem 0.625rem;
  height: 2.375rem;
  width: 100%;
  line-height: 1.3;
  &:focus {
    outline: none;
  }
`;

const sfHasValueStyle = css`
  label {
    display: none;
  }
`;

const sfLabelStyle = css`
  color: #7b8794;
  left: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
`;

const sfInputContainer = css`
  position: relative;
  flex: 1;
`;

export const InputComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    type,
    isInErrorState,
    error,
    invalid,
    placeholder,
    onAddOnClick,
    addOnLabel,
    onBlur,
    touched,
    disabled,
    min,
    readOnly,
    onChangeSideEffect,
    onChangeDebounced,
    required,
    onFocus,
    fullwidth,
    salesForce
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(debounce((e, value) => onChangeDebounced(e, value), 250), []);
  const handleChange = e => {
    onChange(e);
    onChangeDebounced && debouncedCallback(e, e.target.value);
    onChangeSideEffect && onChangeSideEffect(e.target.value);
  };

  const handleNumberChange = e => {
    (/^-?\d+$/.test(e.target.value) || e.target.value === '') && onChange(e);
    onChangeDebounced && debouncedCallback(e, e.target.value);
    onChangeSideEffect && onChangeSideEffect(e.target.value);
  };

  return (
    <div className={cx(inputContainerStyle, fullwidth && fullWidthStyle, salesForce && sfMarginStyle)}>
      <InputGroup className={stretchedContainerStyle}>
        <div
          className={cx(
            salesForce ? sfInputContainer : customInputContainer,
            value && (salesForce ? sfHasValueStyle : hasValueState)
          )}
        >
          <Input
            name={name}
            id={id}
            value={value}
            invalid={invalid}
            onChange={type === 'number' ? handleNumberChange : handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            min={min}
            readOnly={readOnly}
            required={required}
            className={
              disabled || readOnly
                ? disabledStyle
                : cx(
                    salesForce ? sfInputStyle : customInputStyle,
                    isInErrorState && touched && !salesForce && errorBorderStyle
                  )
            }
          />
          <label className={cx(labelStyle, salesForce && sfLabelStyle)}>{placeholder}</label>
          <div className={cx(borderStyle, 'borderDiv')} />
        </div>
        {onAddOnClick && addOnLabel && (
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              onClick={e => onAddOnClick(e, value)}
              disabled={disabled}
              className={groupButtonStyle}
            >
              <div className={addOnLabelStyle}>{addOnLabel}</div>
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {isInErrorState && touched && <RequiredError errorMessage={error} salesForce={salesForce} />}
    </div>
  );
};

export const InputField = props => {
  const {
    label,
    name,
    id,
    type,
    placeholder,
    onAddOnClick,
    addOnLabel,
    disabled,
    min,
    onChangeSideEffect,
    onChangeDebounced,
    required,
    fixedValue,
    readOnly,
    fullwidth,
    salesForce
  } = props;
  const { value, onBlur, setValue, isInErrorState, errorMessage, touched, onFocus, focused } = useFormikField(name);

  return (
    <InputComponent
      name={name}
      id={id}
      label={label}
      type={type}
      value={fixedValue || value || ''}
      invalid={isInErrorState}
      isInErrorState={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onAddOnClick={onAddOnClick}
      addOnLabel={addOnLabel}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      touched={touched}
      disabled={disabled}
      readOnly={readOnly}
      min={min}
      onChangeSideEffect={onChangeSideEffect}
      onChangeDebounced={onChangeDebounced}
      required={required}
      salesForce={salesForce}
      fullwidth={fullwidth}
    />
  );
};
