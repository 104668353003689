import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppForm } from '../AppForm/AppForm';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { useCreateInitialRenewal } from '../../api/api';
import { Loader } from '../components/Loader';
import { quoteStore } from '../../store/quote-store';
import { renewalStore } from '../../store/renewal-store';

export const RenewalWrapper = () => {
  const { id, userid } = useParams();
  renewalStore.policyReference = id;
  quoteStore.userId = userid;
  const { mutate: createInitialRenewal, isLoading } = useCreateInitialRenewal();

  useEffect(() => {
    stepStore.goToStep('renewalPage');
    createInitialRenewal();
    stateStore.setInRenewalMode(true);
  }, [createInitialRenewal]);
  if (isLoading) {
    return <Loader message="Please wait." />;
  }

  return <AppForm />;
};
